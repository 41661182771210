import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const normalizeUrl = (url: string) => {
  let normalizedUrl = url.trim();
  if (!normalizedUrl.startsWith('/')) {
    normalizedUrl = '/' + normalizedUrl;
  }
  if (normalizedUrl.endsWith('/')) {
    normalizedUrl = normalizedUrl.slice(0, normalizedUrl.length - 1);
  }
  return normalizedUrl;
};

interface Props {
  open: boolean;
  onCancel: () => void;
  onSave: (commitUrl: string) => void;
}
const NewFileDialog = ({ open, onCancel, onSave }: Props) => {
  const [url, setUrl] = React.useState('');
  const [error, setError] = React.useState('');
  const [modified, setModified] = React.useState(false);
  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setUrl(e.target.value);
      setModified(true);
    },
    [],
  );
  const onSaveHandler = React.useCallback(() => {
    setModified(true);
    if (url.match(/^(http|https):\/\/.*$/)) {
      setError('URLは /informations/2020/example の形式で入力してください');
      return;
    }
    if (url.match(/\/\//)) {
      setError('連続した / は使えません');
      return;
    }

    const normalizedUrl = normalizeUrl(url);
    setUrl(normalizedUrl);
    setError('');
    if (modified && normalizedUrl === '') {
      setError('URLを入力してください');
      return;
    }

    if (!normalizedUrl.match(/^[\w/-]+$/)) {
      setError('URLには半角英数字と _ - / のみが利用できます');
      return;
    }

    onSave(normalizedUrl);
  }, [onSave, modified, url]);
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>新規作成</DialogTitle>
      <DialogContent>
        <DialogContentText>
          URLを入力してください。
          <br />
          リンク切れを防ぐため、原則として一度保存したページのURLは変更できません。
        </DialogContentText>
        <TextField
          placeholder="/informations/2020/example"
          autoFocus
          margin="dense"
          id="name"
          label="URL"
          type="text"
          fullWidth
          value={url}
          onChange={onChange}
          error={error !== ''}
          helperText={error}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          キャンセル
        </Button>
        <Button onClick={onSaveHandler} color="primary" variant="contained">
          確定
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default NewFileDialog;
