import { IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React from 'react';
import { useToggle } from 'react-use';
import { Frontmatter } from '../../utils/markdown';
import PrimaryFab from '../atoms/PrimaryFab';
import TitleWithButtons from '../molecules/TitleWithButtons';
import FrontmatterInput from '../organisms/editor/FrontmatterInput';
import Preview from '../organisms/editor/Preview';
import Header from '../organisms/Header';
interface Props {
  url: string;
  moveToHome: () => void;
  save: () => void;
  showPhotos: () => void;
  showStyleSelector: () => void;
  markdown: string;
  setMarkdown: (markdown: string) => void;
  frontmatter: Frontmatter;
  setFrontmatter: (frontmatter: Frontmatter) => void;
}

const MarkdownEditorTemplate = React.forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      url,
      moveToHome,
      save,
      showPhotos,
      showStyleSelector,
      markdown,
      setMarkdown,
      frontmatter,
      setFrontmatter,
    },
    ref,
  ) => {
    const [isShowingPreview, togglePreview] = useToggle(false);
    const markdownError = markdown.length === 0 ? '入力してください' : null;
    const [frontmatterError, setFrontmatterError] = React.useState(false);
    const backButton = React.useMemo(() => {
      return (
        <IconButton onClick={moveToHome}>
          <ClearIcon fontSize="small" />
        </IconButton>
      );
    }, [moveToHome]);
    const onChangeHandler = React.useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setMarkdown(e.target.value),
      [setMarkdown],
    );

    return (
      <>
        <Header>
          <TitleWithButtons
            title={`/${url}`}
            titleAlign={'left'}
            backButton={backButton}
            actions={[
              isShowingPreview || (
                <IconButton onClick={showStyleSelector}>
                  <TextFieldsIcon fontSize="small" />
                </IconButton>
              ),
              isShowingPreview || (
                <IconButton onClick={showPhotos}>
                  <PhotoLibraryIcon fontSize="small" />
                </IconButton>
              ),
              <IconButton onClick={togglePreview}>
                {isShowingPreview
                  ? (
                  <EditIcon fontSize="small" />
                    )
                  : (
                  <VisibilityIcon fontSize="small" />
                    )}
              </IconButton>,
            ]}
          />
        </Header>
        {isShowingPreview
          ? (
          <Preview frontmatter={frontmatter} markdown={markdown} url={url} />
            )
          : (
          <>
            <FrontmatterInput
              frontmatter={frontmatter}
              setFrontmatter={setFrontmatter}
              setHasError={setFrontmatterError}
            />
            <TextField
              label="本文"
              multiline
              value={markdown}
              onChange={onChangeHandler}
              variant="outlined"
              helperText={markdownError}
              error={!!markdownError}
              fullWidth
              inputRef={ref}
            />
          </>
            )}
        <PrimaryFab
          onClick={save}
          disabled={!!markdownError || frontmatterError}
          icon={<SaveIcon />}
        />
      </>
    );
  },
);

export default MarkdownEditorTemplate;
