import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import React from 'react';

interface Props {
  sizeLimitMb?: number;
  onChange: (file: File) => void;
}
const UploadButton = ({ sizeLimitMb, onChange }: Props) => {
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<CloudUploadIcon />}
        component="label"
      >
        アップロード
        <input
          type="file"
          style={{ display: 'none' }}
          multiple={false}
          accept="image/*"
          onChange={(e) => {
            const files = e.target.files;
            if (files === null || files.length === 0) {
              return;
            }
            if (
              sizeLimitMb != null &&
              files[0].size > sizeLimitMb * 1024 * 1024
            ) {
              alert(`${sizeLimitMb} MB より小さな画像が使用できます`);
              return;
            }
            onChange(files[0]);
          }}
        />
      </Button>
    </>
  );
};

export default UploadButton;
