import { Paper, Slide, styled } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom';

const StyledMask = styled('div')(() => ({
  position: 'fixed',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0)',
  top: 0,
  left: 0,
}));
const StyledPaper = styled(Paper)(({ theme }) => ({
  margin: 0,
  width: '100%',
  minHeight: theme.spacing(4),
  maxHeight: 'calc(100vh - 32px)',
  overflow: 'auto',
  position: 'fixed',
  bottom: 0,
  left: 0,
}));

// 早くMaterialIOで対応してくれ
// TODO 高さが足りないときにスワイプで全画面にする機能

interface Props {
  isShowing: boolean;
  onHide?: () => void;
  children?: React.ReactNode;
}
const BottomSheet = ({ isShowing, onHide, children }: Props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <>
          {isShowing && <StyledMask onClick={onHide} />}
          <Slide direction="up" in={isShowing} mountOnEnter unmountOnExit>
            <StyledPaper elevation={3}>{children}</StyledPaper>
          </Slide>
        </>,
        document.body,
      )}
    </>
  );
};

export default BottomSheet;
