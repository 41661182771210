import { createSlice } from '@reduxjs/toolkit';

export interface UserState {
  email: string | null;
  state: 'loading' | 'signedIn' | 'signedOut';
  authorized: boolean;
}
const initialState: UserState = {
  email: null,
  state: 'loading',
  authorized: true,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      return { ...state, email: action.payload, state: 'signedIn' };
    },
    clearUser: (state) => {
      return { ...state, email: null, state: 'signedOut' };
    },
    setAuthorized: (state, action) => {
      return { ...state, authorized: action.payload };
    },
  },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { setUser, clearUser, setAuthorized } = slice.actions;
