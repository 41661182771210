import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import format from 'date-fns/format';
import { fetchFile, storeFile } from '../utils/gitlabUtils';
import { rootPath } from '../env';
export interface File {
  path: string;
  content: string;
}
export interface FileState {
  file: File | null;
  loading: boolean;
  saving: boolean;
  isNewFile: boolean;
}
const initialState: FileState = {
  file: null,
  loading: false,
  saving: false,
  isNewFile: false,
};

const createDefaultFile = (path: string) => {
  const date = format(new Date(), 'yyyy-MM-dd');
  const content = `---
title: ${path}
date: ${date}
---
# 見出し
本文
`;
  return { path, content };
};

export const loadFile = createAsyncThunk('file/load', async (path: string) => {
  try {
    return await fetchFile(rootPath + '/' + path + '/index.md');
  } catch (e) {
    return Promise.reject();
  }
});

export const saveFile = createAsyncThunk(
  'file/save',
  async (params: {
    path: string;
    content: string;
    commitMessage: string;
    authorEmail: string;
    authorName: string;
    isNewFile: boolean;
  }) => {
    const {
      path,
      content,
      commitMessage,
      authorEmail,
      authorName,
      isNewFile: newFile,
    } = params;
    await storeFile(
      { newFile, path, content },
      commitMessage,
      authorEmail,
      authorName,
    );
    return { path, content };
  },
);

const slice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    createNewFile: (state, action) => {
      return {
        ...state,
        isNewFile: true,
        file: createDefaultFile(action.payload),
      };
    },
    clearFile: (state) => {
      return { ...state, file: null, isNewFile: false };
    },
    setLoading: (state, action) => {
      return { ...state, loading: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveFile.pending, (state) => {
      return { ...state, saving: true };
    });
    builder.addCase(saveFile.rejected, (state) => {
      return { ...state, saving: false };
    });
    builder.addCase(saveFile.fulfilled, (state, action) => {
      return {
        ...state,
        file: action.payload,
        saving: false,
        isNewFile: false,
      };
    });
    builder.addCase(loadFile.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(loadFile.rejected, (state) => {
      return { ...state, loading: false };
    });
    builder.addCase(loadFile.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        file: action.payload,
        isNewFile: false,
      };
    });
  },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { clearFile, createNewFile } = slice.actions;
