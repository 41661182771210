import React from 'react';
import { Circles } from 'react-loader-spinner';
import styled from 'styled-components';

const LoadingSpinner = () => {
  return (
    <Layout>
      <VerticalCenter>
        <SpinnerWrapper>
          <Circles color="#0a6cff" />
        </SpinnerWrapper>
      </VerticalCenter>
    </Layout>
  );
};

const Layout = styled.div`
  height: 100%;
  width: 100%;
  display: table;
`;
const VerticalCenter = styled.div`
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
`;

const SpinnerWrapper = styled.div`
  width: 80px;
  height: 80px;
  margin: 0 auto;
`;

export default LoadingSpinner;
