import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import { firebaseConfig } from '../env';

export const init = (onStateChange: (user: null | firebase.User) => void) => {
  firebase.initializeApp(firebaseConfig);
  firebase.auth().onAuthStateChanged(onStateChange);
};

export const signIn = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/userinfo.email');
  firebase.auth().useDeviceLanguage();
  firebase.auth().signInWithRedirect(provider);
};

export const signOut = () => {
  firebase.auth().signOut();
};

export const currentUser = () => {
  const user = firebase.auth().currentUser;
  if (user === null) {
    throw new Error('No login info');
  }
  const { email, displayName } = user;
  if (email === null || displayName === null) {
    throw new Error('Can not load email/name');
  }
  return { email, displayName };
};
