import React from 'react';

export const useBool: (
  initialValue?: boolean,
) => [boolean, () => void, () => void, () => void] = (initialValue = false) => {
  const [value, setValue] = React.useState(initialValue);

  const setTrue = React.useCallback(() => {
    setValue(true);
  }, []);

  const setFalse = React.useCallback(() => {
    setValue(false);
  }, []);

  const toggle = React.useCallback(() => {
    setValue((prev) => !prev);
  }, []);

  return [value, setTrue, setFalse, toggle];
};
