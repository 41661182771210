import { Fab, styled } from '@mui/material';
import React from 'react';

const StyledFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
}));

interface Props {
  icon: NonNullable<React.ReactNode>;
  onClick?: () => void;
  disabled?: boolean;
}
const PrimaryFab = ({ icon, onClick, disabled }: Props) => {
  return (
    <StyledFab
      color="primary"
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
    </StyledFab>
  );
};
export default PrimaryFab;
