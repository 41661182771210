import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../stores';
import { clearUser, setUser } from '../../stores/user';
import { init } from '../../utils/googleAuthentication';
import LoadingSplash from '../organisms/splash/LoadingSplash';
import LoginSplash from '../organisms/splash/LoginSplash';
import UnauthorizedSplash from '../organisms/splash/UnauthorizedSplash';
interface Props {
  children?: React.ReactChild;
}

const SplashModal: React.FC<Props> = ({ children }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);

  React.useEffect(() => {
    init((user) => {
      if (user) {
        dispatch(setUser(user.email));
      } else {
        dispatch(clearUser());
      }
    });
  }, [dispatch]);

  const userState = user.state;
  const authorized = user.authorized;
  if (userState === 'loading') {
    return <LoadingSplash />;
  }
  if (userState === 'signedOut') {
    return <LoginSplash />;
  }
  if (!authorized) {
    return <UnauthorizedSplash />;
  }
  return <>{children}</>;
};

export default SplashModal;
