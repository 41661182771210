import React from 'react';

/**
 * [[text]](url) を button で囲んだ a タグにする
 * @param props
 */
const MyAnchor: React.FC<React.AnchorHTMLAttributes<HTMLAnchorElement>> = (
  props,
) => {
  // <a>[text]</a> のみ置換したい。この形ではchildren.length == 1
  // 例えば <a><span>hoge</span>fuga<span>piyo</span></a> の場合はchildren.length == 3
  const children = props.children as React.ReactChild[];
  const replacedProps = { ...props, href: trimTrailingSlash(props.href) };
  if (children?.length !== 1 || typeof children?.[0] !== 'string') {
    return <a {...replacedProps}>{props.children}</a>;
  }
  const match = (children?.[0] as string)?.match(/\[(.*)\]/);

  if (match) {
    return (
      <span className="button">
        <a {...replacedProps}>{match[1]}</a>
      </span>
    );
  }
  return <a {...replacedProps}>{props.children}</a>;
};

const trimTrailingSlash = (str: string | undefined): string | undefined => {
  if (str === undefined) {
    return undefined;
  }
  // 外部リンクは加工しない
  if (str.startsWith('http')) {
    return str;
  }
  return str.replace(/\/$/, '');
};

export default MyAnchor;
