import { configureStore } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch as rawUseDispatch,
  useSelector as rawUseSelector,
} from 'react-redux';
import { combineReducers } from 'redux';
import fileReducer from './file';
import pagesReducer from './pages';
import userReducer from './user';
import picturesReducer from './pictures';

const reducer = combineReducers({
  user: userReducer,
  pages: pagesReducer,
  file: fileReducer,
  pictures: picturesReducer,
});

const store = configureStore({ reducer });

// 複数のreducerをまとめた場合に、最終的なStateの型を取り出す
export type RootState = ReturnType<typeof store.getState>;

// 型情報付きのuseSelectorをここで宣言
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;

// 型情報付きのuseDispatchを宣言
export type AppDispatch = typeof store.dispatch;
export const useDispatch: () => AppDispatch = () => {
  return rawUseDispatch<AppDispatch>();
};

export default store;
