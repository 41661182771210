import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchTree } from '../utils/gitlabUtils';

export interface PagesState {
  loading: boolean;
  lastCommit: string | null;
  hasNext: boolean;
  endCursor: string | null;
  pages: string[];
}

const initialState: PagesState = {
  loading: false,
  lastCommit: null,
  hasNext: false,
  endCursor: null,
  pages: [],
};

export const loadPages = createAsyncThunk('pages/load', fetchTree);

const slice = createSlice({
  name: 'Pages',
  initialState,
  reducers: {
    clearPages: (state) => {
      return { ...state, ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadPages.pending, (state) => {
      return { ...state, loading: true };
    });
    builder.addCase(loadPages.rejected, (state) => {
      return { ...state, loading: false };
    });
    builder.addCase(loadPages.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        pages: [...state.pages, ...action.payload.pages],
      };
    });
  },
});

// Reducerをエクスポートする
export default slice.reducer;

// Action Creatorsをエクスポートする
export const { clearPages } = slice.actions;
