import { unwrapResult } from '@reduxjs/toolkit';
import React from 'react';
import { rootPath } from '../../env';
import { RootState, useDispatch, useSelector } from '../../stores';
import {
  clearNewPicture,
  loadFileList,
  Picture,
  uploadNewFile,
  uploadPicture,
} from '../../stores/pictures';
import {
  relativePathToAbsolutePath,
  absolutePathToRelativePath,
} from '../../types/path';
import { fetchFile } from '../../utils/gitlabUtils';
import { currentUser } from '../../utils/googleAuthentication';
import PicturesSelectionDialog from '../organisms/pictures/PictureSelectionDialog';
import PictureUploadDialog from '../organisms/pictures/PictureUploadDialog';

interface Props {
  open: boolean;
  onCancel: () => void;
  onSubmit: (path: string) => void;
  basePath: string;
}

const PicturesDialogContainer = ({
  onCancel,
  open,
  onSubmit,
  basePath,
}: Props) => {
  const dispatch = useDispatch();
  const { saving, newPicture, picturePaths } = useSelector(
    (state: RootState) => state.pictures,
  );
  const [pictures, setPictures] = React.useState<Picture[]>([]);
  const [relativePath, setRelativePath] = React.useState('./');
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    dispatch(clearNewPicture());
  }, [dispatch, open]);

  React.useEffect(() => {
    dispatch(
      loadFileList(
        relativePathToAbsolutePath(
          `${rootPath}/${basePath}`,
          relativePath,
        ).slice(1),
      ),
    );
  }, [basePath, relativePath, dispatch]);

  React.useEffect(() => {
    setLoading(true);
    const pictures = picturePaths.map(async (path) => {
      const content = (await fetchFile(path)).content;
      return { content, path };
    });
    Promise.all(pictures)
      .then(setPictures)
      .finally(() => setLoading(false));
  }, [picturePaths]);

  if (newPicture) {
    return (
      <PictureUploadDialog
        relativePath={relativePath}
        isSaving={false} // todo
        newPicture={newPicture}
        onCancel={onCancel}
        onSave={(commitMessage) => {
          const path = relativePathToAbsolutePath(
            `${rootPath}/${basePath}`,
            `${relativePath}${newPicture.fileName}`,
          );
          const { displayName, email } = currentUser();
          const param = {
            path,
            content: newPicture.content,
            commitMessage,
            authorEmail: email,
            authorName: displayName,
          };
          dispatch(uploadPicture(param))
            .then(unwrapResult)
            .then(() => {
              onSubmit(`${relativePath}${newPicture.fileName}`);
            })
            .catch((_) => {
              alert('画像の保存に失敗しました');
            });
        }}
        open={open}
      />
    );
  }

  return (
    <PicturesSelectionDialog
      open={open}
      relativePath={relativePath}
      onRelativePathChange={setRelativePath}
      onCancel={onCancel}
      pictures={pictures}
      onSave={(absolutePath) => {
        if (!absolutePath.startsWith('/')) {
          absolutePath = '/' + absolutePath;
        }
        const imgPath = absolutePathToRelativePath(
          absolutePath,
          `/${rootPath}/${basePath}`,
        );
        onSubmit(imgPath);
      }}
      uploadNewPicture={(newPicture) => dispatch(uploadNewFile(newPicture))}
      loading={loading}
      saving={saving}
    />
  );
};

export default PicturesDialogContainer;
