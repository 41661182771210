import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import { projectId, project, rootPath, branch } from '../env';

const graphQlUrl = 'https://gitlab.com/api/graphql';
const apiBaseUrl = `https://gitlab.com/api/v4/projects/${projectId}`;

interface RequestBody {
  url: string;
  method: 'POST' | 'GET';
  body?: string;
}

interface File {
  newFile: boolean;
  path: string;
  content: string;
}

const proxyRequest = async (request: RequestBody) => {
  try {
    // 循環参照しないように一旦コメントアウト
    // todo: 認証に失敗したら認証画面を出す
    // store.dispatch(setAuthorized(true));
    const res = await firebase.functions().httpsCallable('proxy')(request);
    return res.data;
  } catch (e: any) {
    if (e.code === 'permission-denied') {
      // store.dispatch(setAuthorized(false));
      return;
    } else if (e.code === 'internal') {
      throw e;
    }
    console.log('failed to request via proxy');
    console.error({ ...e });
    throw e;
  }
};

const get = (url: string) => {
  return proxyRequest({
    url,
    method: 'GET',
  });
};

const post = (url: string, body: string) => {
  return proxyRequest({
    url,
    body,
    method: 'POST',
  });
};

export const fetchTree = async (path?: string) => {
  const query = `query {
    project(fullPath: "${project}") {
      path
      repository {
        rootRef
        tree(path: "${path ?? rootPath}", recursive: true, ref: "${branch}") {
          lastCommit {
            id
          }
          blobs {
            edges {
              node {
                type
                path
                id
              }
            }
            pageInfo {
              hasNextPage
              endCursor
              startCursor
            }
          }
        }
      }
    }
  }
  `;

  const result = await post(graphQlUrl, JSON.stringify({ query }));
  const { lastCommit, blobs } = result.data.project.repository.tree;
  return {
    lastCommit: lastCommit as { id: string },
    hasNext: blobs.pageInfo.hasNextPage as boolean,
    endCursor: blobs.pageInfo.endCursor as string,
    pages: blobs.edges.map((edge: any) => edge.node.path) as string[],
  };
};

export const fetchFile = async (path: string) => {
  const encodedPath = encodeURIComponent(path);
  const response = await get(
    `${apiBaseUrl}/repository/files/${encodedPath}?ref=${branch}`,
  );
  if (response.message?.includes('404')) {
    throw new Error('file not found');
  }
  return parseResponseJson(response);
};

export const storeFile = async (
  file: File,
  commitMessage: string,
  authorEmail: string,
  authorName: string,
) => {
  const content = file.path.endsWith('md')
    ? btoa(unescape(encodeURIComponent(file.content)))
    : file.content;
  const body = {
    branch,
    commit_message: commitMessage,
    author_email: authorEmail,
    author_name: authorName,
    actions: [
      {
        action: file.newFile ? 'create' : 'update',
        encoding: 'base64',
        file_path: file.path,
        content,
      },
    ],
  };
  const url = apiBaseUrl + '/repository/commits';
  await post(url, JSON.stringify(body));
};

const parseResponseJson: (data: any) => File = (data) => {
  if (data?.file_name) {
    if (data.file_name.endsWith('.md')) {
      return {
        path: data.file_path,
        content: decodeURIComponent(escape(atob(data.content))),
        newFile: false,
      };
    } else {
      return { path: data.file_path, content: data.content, newFile: false };
    }
  }
  throw new Error(`can not parse the response: ${JSON.stringify(data)}`);
};
