import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import React from 'react';
import styled from 'styled-components';
import { Frontmatter } from '../../../utils/markdown';

type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

interface Props {
  frontmatter: Frontmatter;
  setFrontmatter: (value: Frontmatter) => void;
  setHasError?: (hasError: boolean) => void;
}

const FrontmatterInput = ({
  frontmatter,
  setFrontmatter,
  setHasError,
}: Props) => {
  const [innerValue, setInnerValue] =
    React.useState<Nullable<Frontmatter>>(frontmatter);

  const onChangeHandler = React.useCallback(
    (value: Nullable<Frontmatter>) => {
      setInnerValue(value);
      setHasError?.(value.title === '');
      if (!value.title) {
        return;
      }
      const newValue: Frontmatter = { title: value.title };
      if (value.date != null) {
        newValue.date = value.date;
      }
      if (value.noindex) {
        newValue.noindex = value.noindex;
      }
      setFrontmatter(newValue);
    },
    [setFrontmatter, setHasError],
  );

  const canAddItem =
    innerValue.date === undefined || innerValue.noindex === undefined;
  return (
    <FrontmatterFields>
      <FrontmatterField>
        <TextField
          fullWidth
          label="タイトル"
          value={innerValue.title}
          onChange={(e) => {
            onChangeHandler({ ...innerValue, title: e.target.value });
          }}
          error={innerValue.title === ''}
          helperText={innerValue.title === '' && '入力してください'}
        />
      </FrontmatterField>
      {innerValue.date !== undefined && (
        <FrontmatterField>
          <DatePicker
            label="最終更新日"
            format="yyyy-MM-dd"
            value={
              innerValue.date &&
              parse(innerValue.date, 'yyyy-MM-dd', new Date())
            }
            onChange={(value) => {
              const date = value ? format(value, 'yyyy-MM-dd') : null;
              onChangeHandler({ ...innerValue, date });
            }}
          />
          <IconButton
            onClick={() => {
              const fm = { ...innerValue };
              delete fm.date;
              onChangeHandler(fm);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </FrontmatterField>
      )}
      {innerValue.noindex !== undefined && (
        <FrontmatterField>
          <FormControlLabel
            control={<Checkbox checked={!!innerValue.noindex} name="noindex" />}
            label="Googleの検索対象にしない"
            onChange={(e, value) => {
              onChangeHandler({ ...innerValue, noindex: value });
            }}
          />
          <IconButton
            onClick={() => {
              const fm = { ...innerValue };
              delete fm.noindex;
              onChangeHandler(fm);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </FrontmatterField>
      )}
      {canAddItem && (
        <FrontmatterField>
          <Button
            onClick={() => {
              const date = format(new Date(), 'yyyy-MM-dd');
              const fm = { date, noindex: false, ...innerValue };
              onChangeHandler(fm);
            }}
            size="small"
            color="primary"
            startIcon={<AddIcon />}
          >
            メタデータを追加
          </Button>
        </FrontmatterField>
      )}
      <Dummy />
    </FrontmatterFields>
  );
};

const FrontmatterFields = styled.div`
  margin: 12px 0 8px 0;
  display: flex;
  flex-wrap: wrap;
`;

const FrontmatterField = styled.div`
  margin: 8px 8px 8px 0;
  flex-grow: 1;
  display: flex;
  &:first-child {
    flex-grow: 1;
  }
`;

const Dummy = styled.div`
  flex-grow: 9;
`;
export default FrontmatterInput;
