import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from '../../stores';
import { createNewFile, clearFile } from '../../stores/file';
import { loadPages } from '../../stores/pages';
import { signOut } from '../../utils/googleAuthentication';
import LoadingSplash from '../organisms/splash/LoadingSplash';
import NewFileDialog from '../organisms/dialogs/NewFileDialog';
import MainPageTemplate from '../templates/MainPageTemplate';
import { useMount } from 'react-use';

const MainPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pages = useSelector((state: RootState) => state.pages);
  const isEmpty = pages.pages.length === 0;
  const [modalOpening, setModalOpening] = React.useState(false);
  React.useEffect(() => {
    if (isEmpty) {
      dispatch(loadPages() as any); // fixme
    }
  }, [dispatch, isEmpty]);
  useMount(() => {
    dispatch(clearFile());
  });
  const onUrlClick = React.useCallback(
    (url: string) => {
      navigate('/' + url);
    },
    [navigate],
  );
  const showNewFileDialog = React.useCallback(() => {
    setModalOpening(true);
  }, []);
  const onAddNewPage = React.useCallback(
    (url: string) => {
      dispatch(createNewFile(url));
      navigate(url);
    },
    [navigate, dispatch],
  );
  if (pages.loading) {
    return <LoadingSplash />;
  }
  return (
    <>
      <MainPageTemplate
        urls={pages.pages
          .filter((url) => url.endsWith('index.md'))
          .map((url) =>
            url.replace(/^www\/src\/content\//, '').replace(/\/index.md/, ''),
          )}
        onUrlClick={onUrlClick}
        signOut={signOut}
        onAddButtonClick={showNewFileDialog}
      />
      <NewFileDialog
        onCancel={() => setModalOpening(false)}
        onSave={onAddNewPage}
        open={modalOpening}
      />
    </>
  );
};

export default MainPage;
