import Button from '@mui/material/Button';
import 'firebase/auth';
import 'firebase/firestore';
import React from 'react';

interface Props {
  signIn: () => void;
}
export const GoogleSignInButton = ({ signIn }: Props) => {
  return (
    <Button onClick={signIn} variant="contained" color="primary" size="large">
      Googleでログイン
    </Button>
  );
};

export default GoogleSignInButton;
