import React from 'react';
import styled from 'styled-components';
import { NewPicture } from '../../../stores/pictures';
import EncodedImage from '../../atoms/EncodedImage';
import CommitDialog from '../dialogs/CommitDialog';

interface Props {
  open: boolean;
  onCancel: () => void;
  onSave: (comment: string) => void;
  relativePath: string; // ends with slash. e.g. ./ ../ ../../image/
  newPicture: NewPicture;
  isSaving: boolean;
}

const PictureUploadDialog = ({
  open,
  onCancel,
  onSave,
  newPicture,
  relativePath,
  isSaving,
}: Props) => {
  const fileType = newPicture.fileName.split('.').pop()!.toLowerCase();
  return (
    <CommitDialog
      open={open}
      isSaving={isSaving}
      onCancel={onCancel}
      onSave={onSave}
      dialogContent={
        <>
          <p>
            {relativePath}
            {newPicture.fileName}
          </p>
          <ImageContainer>
            <EncodedImage content={newPicture.content} fileType={fileType} />
          </ImageContainer>
        </>
      }
    />
  );
};
export default PictureUploadDialog;

const ImageContainer = styled.div`
  width: 250px;
  text-align: center;
  margin: 0 auto;
`;
