import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import EncodedImage from '../../atoms/EncodedImage';
import styled from 'styled-components';
import { Picture, NewPicture } from '../../../stores/pictures';
import UploadButton from '../../atoms/UploadButton';

interface Props {
  open: boolean;
  onCancel: () => void;
  onSave: (path: string) => void;
  pictures: Picture[];
  relativePath: string;
  onRelativePathChange: (path: string) => void;
  uploadNewPicture: (newPicture: NewPicture) => void;
  loading: boolean;
  saving: boolean;
}
const empty = (
  <DialogContentText>この階層に保存された画像はありません</DialogContentText>
);

const convertFile = async (file: File) => {
  return new Promise<{ fileName: string; content: string }>(
    (resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener(
        'load',
        function () {
          // convert image file to base64 string
          const content = (reader.result as string).split(',')[1];
          if (content) {
            resolve({ fileName: file.name, content });
          } else {
            reject();
          }
        },
        false,
      );
    },
  );
};

const PictureSelectionDialog = ({
  open,
  onCancel,
  onSave,
  pictures,
  loading,
  relativePath,
  uploadNewPicture,
}: Props) => {
  const [selectedPath, selectPath] = React.useState<null | string>(null);
  const onCancelClick = React.useCallback(() => {
    selectPath(null);
    onCancel();
  }, [onCancel]);

  React.useEffect(() => {
    selectPath(null);
  }, [open]);

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>画像を選択</DialogTitle>
      <DialogContent>
        <p>{relativePath}</p>
        {loading ? (
          <p>読込中...</p>
        ) : pictures.length === 0 ? (
          empty
        ) : (
          <Images>
            {pictures.map((picture) => {
              // 一旦「拡張子＝ファイルタイプ」で決め打ち
              const fileType = picture.path.split('.').pop()!.toLowerCase();
              return (
                <ImageContainer
                  onClick={() => selectPath(picture.path)}
                  selected={picture.path === selectedPath}
                  key={picture.path}
                >
                  {picture.content && (
                    <EncodedImage
                      content={picture.content}
                      fileType={fileType}
                    />
                  )}
                </ImageContainer>
              );
            })}
          </Images>
        )}
        <UploadButton
          onChange={async (file) => {
            uploadNewPicture(await convertFile(file));
          }}
          sizeLimitMb={5}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelClick} color="primary">
          キャンセル
        </Button>
        <Button
          onClick={() => {
            if (!selectedPath) {
              return;
            }
            onSave(selectedPath);
          }}
          color="primary"
          variant="contained"
          disabled={selectedPath === null}
        >
          決定
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default PictureSelectionDialog;

const Images = styled.div`
  display: flex;
  flex-flow: wrap;
`;
interface ImageContainerProps {
  selected: boolean;
}
const ImageContainer = styled.div<ImageContainerProps>`
  border: 4px solid ${(props) => (props.selected ? '#00F;' : '#EEE')};
  margin: 0 0 4px 0;
  padding: 4px;
  cursor: pointer;
  flex: auto;
  width: 250px;
  text-align: center;
`;
