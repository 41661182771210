import React from 'react';

const ScrollableTable = (props) => {
  return <div className="scroll">
        <table {...props}>{props.children}</table>
        <div className="shadow" />
    </div>;
};

export default ScrollableTable;
