import CreateIcon from '@mui/icons-material/Create';
import React from 'react';
import styled from 'styled-components';
import { signIn } from '../../../utils/googleAuthentication';
import GoogleSignInButton from '../../atoms/GoogleSignInButton';

const LoginSplash = () => {
  return (
    <Layout>
      <Center>
        <Logo>
          <CreateIcon style={{ fontSize: 90 }} color="primary" />
        </Logo>

        <GoogleSignInButton signIn={signIn} />
      </Center>
    </Layout>
  );
};

const Layout = styled.div`
  height: 100vh;
  width: 100vw;
  display: table;
`;
const Center = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  height: 100%;
`;
const Logo = styled.div`
  margin: 0 0 12px 0;
`;
export default LoginSplash;
