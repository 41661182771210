import React from 'react';
import { useNavigate } from 'react-router';
import { useMount } from 'react-use';
import { rootPath } from '../../env';
import { RootState, useDispatch, useSelector } from '../../stores';
import { createNewFile, File, loadFile, saveFile } from '../../stores/file';
import { currentUser } from '../../utils/googleAuthentication';
import {
  Frontmatter,
  parseMarkdownFile,
  stringfyMarkdown,
} from '../../utils/markdown';
import PictureDialogContainer from '../container/PictureDialogContainer';
import CommitDialog from '../organisms/dialogs/CommitDialog';
import LoadingSplash from '../organisms/splash/LoadingSplash';
import MarkdownEditorTemplate from '../templates/MarkdownEditorTemplate';
import NotFoundTemplate from '../templates/NotFoundTemplate';
import PageViewerTemplate from '../templates/PageViewerTemplate';
import { loadPages } from '../../stores/pages';
import StyleSelector from '../organisms/editor/StyleSelector';
import { useBool } from 'hooks/useBool';
import { undoableInsert } from 'utils/inputElementUtils';

const EditPage: React.FC<{}> = () => {
  // match.params.url には / を含められなかったので location から取得する
  const navigate = useNavigate();
  const url = location.pathname.slice(1); // remove '/'
  const [mode, setMode] = React.useState<'viewer' | 'editor'>('viewer');
  const [isCommitDialogShowing, showCommitDialog, hideCommitDialog] = useBool();
  const [isPhotosDialogOpening, showPhotosDialog, hidePhotosDialog] = useBool();
  const [isStyleSelectorShowing, showStyleSelector, hideStyleSelector] =
    useBool();
  const [newContent, setNewContent] = React.useState('');

  const dispatch = useDispatch();
  const { file, loading, saving, isNewFile } = useSelector(
    (state: RootState) => state.file,
  );

  const moveToHome = React.useCallback(() => {
    navigate('/');
  }, [navigate]);
  const setEditMode = React.useCallback(() => {
    setMode('editor');
  }, []);
  const onSave = React.useCallback(
    async (commitMessage: string) => {
      const path = `${rootPath}/${url}/index.md`;
      const { displayName, email } = currentUser();
      const param = {
        path,
        content: newContent,
        commitMessage,
        authorEmail: email,
        authorName: displayName,
        isNewFile,
      };
      const res = await dispatch(saveFile(param));
      if (saveFile.fulfilled.match(res)) {
        hideCommitDialog();
        setMode('viewer');
        dispatch(loadPages());
        return;
      }
      alert(
        '保存に失敗しました。お手数ですがページを再読み込みして編集をやり直してください。',
      );
    },
    [url, newContent, dispatch, isNewFile, hideCommitDialog],
  );

  const [frontmatter, setFrontmatter] = React.useState<Frontmatter>({
    title: '',
  });
  const [markdown, setMarkdown] = React.useState('');

  useMount(() => {
    dispatch(loadFile(url));
  });

  const loadFromFile = React.useCallback((file: File | null) => {
    if (!file) {
      return;
    }
    const { markdown: m, frontmatter: f } = parseMarkdownFile(file.content);
    setFrontmatter(f);
    setMarkdown(m);
  }, []);

  const initialize = React.useCallback(() => {
    loadFromFile(file);
    setMode('viewer');
  }, [file, loadFromFile]);

  React.useEffect(() => {
    loadFromFile(file);
  }, [file, loadFromFile]);

  const editorRef = React.useRef<HTMLTextAreaElement>(null);

  const save = React.useCallback(() => {
    showCommitDialog();
    setNewContent(stringfyMarkdown(frontmatter, markdown));
  }, [showCommitDialog, frontmatter, markdown]);

  if (loading) {
    return <LoadingSplash />;
  }
  if (!file) {
    return (
      <NotFoundTemplate onAddButtonClick={() => dispatch(createNewFile(url))} />
    );
  }

  if (mode === 'viewer') {
    return (
      <PageViewerTemplate
        url={url}
        moveToHome={moveToHome}
        markdown={markdown}
        frontmatter={frontmatter}
        onEditButtonClick={setEditMode}
      />
    );
  }

  return (
    <>
      <MarkdownEditorTemplate
        moveToHome={initialize}
        save={save}
        showPhotos={showPhotosDialog}
        showStyleSelector={showStyleSelector}
        url={url}
        markdown={markdown}
        setMarkdown={setMarkdown}
        frontmatter={frontmatter}
        setFrontmatter={setFrontmatter}
        ref={editorRef}
      />
      <CommitDialog
        open={isCommitDialogShowing}
        onCancel={hideCommitDialog}
        onSave={onSave}
        isSaving={saving}
      />
      <PictureDialogContainer
        open={isPhotosDialogOpening}
        basePath={url}
        onCancel={hidePhotosDialog}
        onSubmit={(path) => {
          undoableInsert(`\n![説明文](${path})`, '\n', editorRef);
          hidePhotosDialog();
        }}
      />
      <StyleSelector
        isShowing={isStyleSelectorShowing}
        onHide={hideStyleSelector}
        onSelect={(pre, suf = '') => {
          undoableInsert(pre, suf, editorRef);
          hideStyleSelector();
        }}
      />
    </>
  );
};

export default EditPage;
