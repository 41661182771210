import remark from 'remark';
import parse from 'remark-parse';
import GitLabImageBuilder from './GitLabImage';
import React from 'react';
import rehypeParser from 'rehype-parse';
import rehypeReact from 'rehype-react';
import matter from 'gray-matter';
import MyAnchor from '../components/atoms/MyAnchor';
import { parse as parseYaml, defaultOptions } from 'yaml';
import ScrollableTable from '../components/atoms/scrollableTable';

// 型定義がなかった…
const unified = require('unified');
const reactRenderer = require('remark-react');
const sectionize = require('remark-sectionize');

const MockPageList = () => {
  return (
    <span className="attention">プレビューではページ一覧を表示できません</span>
  );
};

// 滅多にメンテナンスされる箇所ではないのでトップ画像の表示は諦める
const MockTopContent = (props: any) => {
  return <div>{props.children}</div>;
};

const rehype = unified()
  .use(rehypeParser, { fragment: true })
  .use(rehypeReact, { createElement: React.createElement });

export const renderHtml = (markdown: string, basePath: string) => {
  const processor = remark()
    .use(sectionize)
    .use(parse)
    .use(reactRenderer, {
      remarkReactComponents: {
        img: GitLabImageBuilder(basePath),
        a: MyAnchor,
        latestchildpages: MockPageList,
        latestpages: MockPageList,
        'top-content': MockTopContent,
        table: ScrollableTable,
      },
      toHast: {
        handlers: {
          html: (h: any, node: any) =>
            // process raw HTML text into HAST so react remark can process it
            rehype.parse(node.value).children,
        },
      },
      sanitize: false,
    });
  const p = processor.processSync(markdown);
  // 型の定義がライブラリのバージョンアップに追従できておらずおかしいのでanyで回避
  return (p as any).result;
};

export interface Frontmatter {
  title: string;
  date?: string;
  noindex?: boolean;
}

export const parseMarkdownFile = (rawText: string) => {
  const { content, data } = matter(rawText, {
    engines: {
      yaml: (s) => {
        const parsed = parseYaml(s, defaultOptions);
        if (parsed === null) {
          return {};
        }
        if (typeof parsed === 'object') {
          return parsed;
        }
        // : がない場合
        if (typeof parsed === 'string') {
          return { parsed: true };
        }
        return {};
      },
    },
  });
  // gray-matter では frontmatter 終了の --- のあとの \n から content と見なすためそれを取り除く
  const markdown = content.startsWith('\n') ? content.slice(1) : content;
  return { markdown, frontmatter: data as Frontmatter };
};

export const stringfyMarkdown = (
  frontmatter: Frontmatter,
  markdown: string,
) => {
  // frontmatter の順序、入れる・入れないを自分で制御したかったためライブラリを使っていない
  const frontmatterItems = [];
  frontmatterItems.push(`title: ${frontmatter.title}`);
  if (frontmatter.date) {
    frontmatterItems.push(`date: ${frontmatter.date}`);
  }
  if (frontmatter.noindex) {
    frontmatterItems.push(`noindex: ${frontmatter.noindex}`);
  }
  return `---
${frontmatterItems.join('\n')}
---
${markdown}`;
};
