import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { signOut } from '../../../utils/googleAuthentication';

const UnauthorizedSplash = () => {
  return (
    <Layout>
      <Center>
        <p>権限がありません。</p>
        <p>管理者にお問い合わせください。</p>
        <Button color={'primary'} variant="contained" onClick={signOut}>
          サインアウト
        </Button>
      </Center>
    </Layout>
  );
};

const Layout = styled.div`
  height: 100vh;
  width: 100vw;
  display: table;
`;
const Center = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  height: 100%;
`;
export default UnauthorizedSplash;
