import format from 'date-fns/format';
import React from 'react';
import { Frontmatter, renderHtml } from '../../../utils/markdown';
import GatsbyHtml from '../../molecules/GatsbyHtml';

interface Props {
  frontmatter: Frontmatter;
  markdown: string;
  url: string;
}
const Preview: React.FC<Props> = ({ frontmatter, markdown, url }) => {
  const html = React.useMemo(() => {
    return (
      <>
        <header>
          <h1>{frontmatter.title}</h1>
          {frontmatter.date && (
            <time>{format(new Date(frontmatter.date), 'yyyy/MM/dd')}</time>
          )}
        </header>
        {renderHtml(markdown, url)}
      </>
    );
  }, [frontmatter, markdown, url]);
  return <GatsbyHtml html={html} />;
};
export default Preview;
