import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { storeFile, fetchTree } from '../utils/gitlabUtils';

export interface Picture {
  path: string;
  content: string | null;
}
export interface NewPicture {
  fileName: string;
  content: string;
}
export interface PictureState {
  picturePaths: string[];
  loading: boolean;
  saving: boolean;
  newPicture: null | NewPicture;
}
const initialState: PictureState = {
  picturePaths: [],
  loading: false,
  saving: false,
  newPicture: null,
};

export const uploadPicture = createAsyncThunk(
  'pictures/upload',
  async (params: {
    path: string;
    content: string;
    commitMessage: string;
    authorEmail: string;
    authorName: string;
  }) => {
    const { path, content, commitMessage, authorEmail, authorName } = params;
    return storeFile(
      { newFile: true, path, content },
      commitMessage,
      authorEmail,
      authorName,
    );
  },
);

const isImageFile = (path: string) => {
  return path.match(/\.(png|jpg|gif)/);
};

export const loadFileList = createAsyncThunk(
  'pictures/load',
  async (path: string) => {
    try {
      const res = await fetchTree(path);
      // うまいことGraphQLで画像のみに絞ることができなかったので泥臭く処理
      return res.pages.filter(isImageFile);
    } catch (e) {
      console.error(e);
      return Promise.reject();
    }
  },
);

const slice = createSlice({
  name: 'pictures',
  initialState,
  reducers: {
    uploadNewFile: (state, action) => {
      return {
        ...state,
        newPicture: action.payload,
      };
    },
    clearNewPicture: (state) => {
      return {
        ...state,
        newPicture: null,
        saving: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadPicture.pending, (state) => {
      return { ...state, saving: true };
    });
    builder.addCase(uploadPicture.fulfilled, (state) => {
      return { ...state, saving: false };
    });
    builder.addCase(uploadPicture.rejected, (state) => {
      return { ...state, saving: false };
    });
    builder.addCase(loadFileList.pending, (state) => {
      return {
        ...state,
        loading: true,
        newPicture: null,
        saving: false,
        comment: null,
      };
    });
    builder.addCase(loadFileList.fulfilled, (state, action) => {
      return { ...state, loading: false, picturePaths: action.payload };
    });
    builder.addCase(loadFileList.rejected, (state) => {
      return { ...state, loading: false };
    });
  },
});

export default slice.reducer;

export const { uploadNewFile, clearNewPicture } = slice.actions;
