import React from 'react';
import { useMount } from 'react-use';
import { fetchFile } from './gitlabUtils';
import { rootPath } from '../env';
import styled from 'styled-components';
import EncodedImage from '../components/atoms/EncodedImage';
import { isAbsolutePath, relativePathToAbsolutePath } from '../types/path';

const GitLabImageBuilder = (basePath: string) => {
  return (props: React.ImgHTMLAttributes<HTMLImageElement>) => (
    <GitLabImage imgProps={props} basePath={basePath} />
  );
};
interface Props {
  imgProps: React.ImgHTMLAttributes<HTMLImageElement>;
  basePath: string;
}
const GitLabImage = ({ imgProps, basePath }: Props) => {
  const [content, setContent] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(true);

  const src = imgProps.src;
  useMount(() => {
    if (isIllegalPath(src) || !src || isAbsolutePath(src)) {
      return;
    }
    fetchFile(rootPath + relativePathToAbsolutePath(basePath, src))
      .then((file) => {
        setContent(file.content);
      })
      .catch((e) => {
        console.error(e);
        setContent(null);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  if (isIllegalPath(src)) {
    return (
      <>
        [画像の指定方法が不正です。直接staticフォルダのURLを指定しないでください]
      </>
    );
  }
  if (loading) {
    return <MockImage>{imgProps.alt ?? ''} 画像読込中...</MockImage>;
  }

  if (!src || isAbsolutePath(src) || content == null) {
    // 万が一 src がない img が渡ってきた場合の防御策 + 外部ソース
    return <img {...imgProps} alt={imgProps.alt} />;
  }

  // 一旦「拡張子＝ファイルタイプ」で決め打ち
  const fileType = src.split('.').pop()!.toLowerCase();
  return (
    <EncodedImage
      fileType={fileType}
      alt={imgProps.alt}
      content={content}
      imgProps={{ ...imgProps, style: { maxWidth: '100%' } }}
    />
  );
};

const MockImage = styled.span`
  display: inline-block;
  padding: 32px;
  background-color: #eee;
`;

const isIllegalPath = (path: string | undefined) => {
  if (!path) {
    return false;
  }
  return (
    path.startsWith('/static/') ||
    path.startsWith('//sola.or.jp/static/') ||
    path.startsWith('http://sola.or.jp/static/') ||
    path.startsWith('https://sola.or.jp/static/')
  );
};

export default GitLabImageBuilder;
