import { Button } from '@mui/material';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import styled from 'styled-components';
import TitleWithButtons from '../molecules/TitleWithButtons';
import Header from '../organisms/Header';
interface Props {
  urls: string[];
  onUrlClick: (url: string) => void;
  signOut: () => void;
  onAddButtonClick?: () => void;
}

const StyledFab = styled(Fab)(() => ({
  position: 'fixed',
}));

const MainPageTemplate = ({
  urls,
  onUrlClick,
  signOut,
  onAddButtonClick,
}: Props) => {
  return (
    <>
      <Header>
        <TitleWithButtons title="ページ一覧" />
      </Header>
      <List>
        {urls.map((url) => {
          return (
            <ListItem
              button
              key={`listItem-${url}`}
              onClick={() => onUrlClick(url)}
            >
              <Item>{url}</Item>
            </ListItem>
          );
        })}
      </List>
      <StyledFab color="primary" sx={{ bp: 2, rp: 2 }}>
        <AddIcon onClick={onAddButtonClick} />
      </StyledFab>
      <Button onClick={signOut}>sign out</Button>
    </>
  );
};

const Item = styled.span`
  width: 100%;
  overflow: scroll;
`;

export default MainPageTemplate;
