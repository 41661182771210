import React from 'react';
import styled from 'styled-components';
import LoadingSpinner from '../../molecules/LoadingSpinner';

const LoadingSplash = () => {
  return (
    <Layout>
      <LoadingSpinner />
    </Layout>
  );
};

const Layout = styled.div`
  height: 100vh;
  width: 100vw;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
`;

export default LoadingSplash;
