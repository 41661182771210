import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import EditPage from './components/pages/EditPage';
import MainPage from './components/pages/MainPage';
import SplashModal from './components/pages/SplashModal';
import './index.css';
import * as serviceWorker from './serviceWorker';
import store from './stores';
import { Helmet } from 'react-helmet';
import { Buffer } from 'buffer';
import { ThemeProvider, createMuiTheme } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ja from 'date-fns/locale/ja';

window.Buffer = window.Buffer || Buffer;

const RootLayout = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
`;

const theme = createMuiTheme();

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>Sola Web Site 編集画面</title>
          <meta name="robots" content="noindex, nofollow"></meta>
        </Helmet>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
          <RootLayout>
            <SplashModal>
              <Router>
                <Routes>
                  <Route path="/" element={<MainPage />}></Route>
                  <Route path="/*" element={<EditPage />}></Route>
                </Routes>
              </Router>
            </SplashModal>
          </RootLayout>
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
