export const isAbsolutePath = (path: string) => {
  return (
    path.startsWith('/') ||
    path.startsWith('//') ||
    path.startsWith('http://') ||
    path.startsWith('https://')
  );
};

/**
 * 相対パスとbaseパスから絶対パスを作る
 * 例）/aaa/bbb + ../xxx/yyy.jpg => /aaa/xxx/yyy.jpg
 * @param basePath
 * @param relativePath
 */
export const relativePathToAbsolutePath = (
  basePath: string,
  relativePath: string,
) => {
  if (!basePath.startsWith('/')) {
    basePath = '/' + basePath;
  }
  if (relativePath.includes('//')) {
    throw new Error(`Illegal relativePath. relativePath: ${relativePath}`);
  }
  if (relativePath.startsWith('/')) {
    return `${basePath}${relativePath}`;
  }
  if (relativePath.startsWith('./')) {
    return `${basePath}${relativePath.slice(1)}`;
  }
  const match = relativePath.match(/((?:\.\.\/)+)(.*)/);
  if (match) {
    const level = match[1].length / '../'.length;
    const parents = basePath.split('/');
    if (parents.length <= level) {
      throw new Error(
        `Illegal relativePath for base. base: ${basePath}, repativePath: ${relativePath}`,
      );
    }
    const parentPath = parents.slice(0, -level).join('/');
    return `${parentPath}/${match[2]}`;
  }
  return `${basePath}/${relativePath}`;
};

/**
 * 絶対パスと絶対パスから相対パスを作る
 * 例）/aaa/bbb/ccc.png + /aaa/ddd => ../bbb/ccc.png
 * 例）/aaa/bbb/ccc.png + /aaa/bbb => ./ccc.png
 * @param absolutePath
 * @param basePath
 */
export const absolutePathToRelativePath: (
  absolutePath: string,
  basePath: string,
) => string = (absolutePath: string, basePath: string) => {
  if (!absolutePath.startsWith('/')) {
    throw new Error(`path must start with /: ${absolutePath}`);
  }
  if (!basePath.startsWith('/')) {
    throw new Error(`path must start with /: ${basePath}`);
  }
  if (absolutePath.includes('//')) {
    throw new Error(`illegal path: ${absolutePath}`);
  }
  if (basePath.includes('//')) {
    throw new Error(`illegal path: ${basePath}`);
  }
  const aPaths = absolutePath.split('/');
  const bPaths = basePath.split('/');
  const { aTail, bTail } = parsePaths(aPaths, bPaths);
  if (bTail.length === 0) {
    return './' + aTail.join('/');
  }
  return '../'.repeat(bTail.length) + aTail.join('/');
};

const parsePaths = (aPaths: string[], bPaths: string[]) => {
  while (true) {
    const aTop = aPaths.shift();
    const bTop = bPaths.shift();
    if (aTop === undefined) {
      return { aTail: [], bTail: [bTop, ...bPaths] };
    }
    if (bTop === undefined) {
      return { aTail: [aTop, ...aPaths], bTail: [] };
    }
    if (aTop !== bTop) {
      return {
        aTail: [aTop, ...aPaths],
        bTail: [bTop, ...bPaths],
      };
    }
  }
};
