import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
  open: boolean;
  isSaving: boolean;
  onCancel: () => void;
  onSave: (commitMessage: string) => void;
  dialogContent?: React.ReactNode;
}
const CommitDialog = ({
  open,
  isSaving,
  onCancel,
  onSave,
  dialogContent,
}: Props) => {
  const [message, setMessage] = React.useState('');
  const [modified, setModified] = React.useState(false);
  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setMessage(e.target.value);
      setModified(true);
    },
    [],
  );
  const onSaveHandler = React.useCallback(() => {
    setModified(true);
    if (message === '') {
      return;
    }
    onSave(message);
  }, [onSave, message]);
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>変更を保存</DialogTitle>
      <DialogContent>
        {dialogContent}
        <DialogContentText>
          どんな変更をしたかを一言で説明してください
        </DialogContentText>
        <TextField
          placeholder="例: 2020年最先端宇宙講演会のお知らせを追加"
          autoFocus
          margin="dense"
          id="name"
          label="変更内容"
          type="text"
          fullWidth
          onChange={onChange}
          error={message === '' && modified}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          キャンセル
        </Button>
        <Button
          onClick={onSaveHandler}
          color="primary"
          variant="contained"
          disabled={isSaving}
        >
          {isSaving ? '保存中' : '保存'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default CommitDialog;
