import BottomSheet from '../../molecules/BottomSheet';
import React from 'react';
import styled from 'styled-components';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

interface Props {
  isShowing: boolean;
  onHide?: () => void;
  onSelect?: (pre: string, suf?: string) => void;
}
const TABLE_MARKDOWN = `
|col1|col2  | col3|
|----|:----:|----:|
|    |center|right|
`;

const CHILD_TREE_MARKDOWN = `
<!-- 子ページの一覧 -->
<LatestChildPages limit=10 excerpt date></LatestChildPages>
`;

const StyleSelector = ({ isShowing, onHide, onSelect }: Props) => {
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <BottomSheet onHide={onHide} isShowing={isShowing}>
      <Tabs
        value={tabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
      >
        <Tab label="文章構造" />
        <Tab label="コンテンツ" />
        <Tab label="装飾" />
      </Tabs>
      <TabPanelContainer>
        <TabPanel value={tabIndex} index={0}>
          <StyleItems>
            <StyleItem onClick={() => onSelect?.('# ')}># 見出し1</StyleItem>
            <StyleItem onClick={() => onSelect?.('## ')}>## 見出し2</StyleItem>
            <StyleItem onClick={() => onSelect?.('- ')}>- 箇条書き</StyleItem>
            <StyleItem onClick={() => onSelect?.('1. ')}>
              1. 番号付きリスト
            </StyleItem>
          </StyleItems>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <StyleItems>
            <StyleItem onClick={() => onSelect?.('[text](', ')')}>
              [text](url) リンク
            </StyleItem>
            <StyleItem onClick={() => onSelect?.('[[text]](', ')')}>
              [[text]](url) ボタンのようなリンク
            </StyleItem>
            <StyleItem>（画像の挿入はヘッダーのメニューから）</StyleItem>
            <StyleItem onClick={() => onSelect?.(TABLE_MARKDOWN)}>
              テーブル
            </StyleItem>
            <StyleItem onClick={() => onSelect?.(CHILD_TREE_MARKDOWN)}>
              子ページ一覧
            </StyleItem>
          </StyleItems>
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <StyleItems>
            <StyleItem onClick={() => onSelect?.('*', '*')}>
              *意味を変える強調*
            </StyleItem>
            <StyleItem onClick={() => onSelect?.('**', '**')}>
              **強い重要性・緊急性**
            </StyleItem>
            <StyleItem onClick={() => onSelect?.('~~', '~~')}>
              ~~取り消し線~~
            </StyleItem>
            <StyleItem onClick={() => onSelect?.('\n---\n')}>
              --- 水平線
            </StyleItem>
          </StyleItems>
        </TabPanel>
      </TabPanelContainer>
    </BottomSheet>
  );
};

interface TabPanelProps {
  value: number;
  index: number;
}
const TabPanel: React.FC<TabPanelProps& {children: React.ReactElement}> = ({ value, index, children }) => {
  return <div hidden={value !== index}>{children}</div>;
};

const TabPanelContainer = styled.div``;

const StyleItems = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const StyleItem = styled.li`
  padding: 16px;
  color: #888888;
`;

export default StyleSelector;
