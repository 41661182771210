import React from 'react';
import styled from 'styled-components';

interface Props {
  children?: React.ReactNode;
}
const Header = ({ children }: Props) => {
  return (
    <>
      <DummyHeader />
      <Layout>{children}</Layout>
    </>
  );
};

const DummyHeader = styled.div`
  position: relative;
  top: 0;
  left: 0;
  margin: 0;
  height: 48px;
  width: 100vw;
`;

const Layout = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  margin: 0;
  height: 48px;
  width: 100vw;
  display: flex;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
`;

export default Header;
