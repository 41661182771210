import React from 'react';

/**
 * 文字列の置き換えをした上でブラウザのUndo機能が使えるようにする
 * see: https://mimemo.io/m/mqLXOlJe7ozQ19r
 * @param pre 選択範囲の前に挿入する文字列
 * @param suf 選択範囲の後に挿入する文字列
 * @param ref 対象となる入力コンポーネントのref（input, textArea）
 */
export const undoableInsert = (
  pre: string,
  suf: string,
  ref: React.RefObject<HTMLTextAreaElement | HTMLInputElement>,
) => {
  let inserted = false;
  const inputElm = ref.current;

  if (!inputElm) {
    return;
  }

  const expectedLen = inputElm.value.length + pre.length + suf.length;
  inputElm.focus();
  const selectionStart = inputElm.selectionStart ?? 0;
  const selectionEnd = inputElm.selectionEnd ?? 0;
  const selectedText = inputElm.value.slice(selectionStart, selectionEnd);
  try {
    inserted = document.execCommand(
      'insertText',
      false,
      pre + selectedText + suf,
    );
  } catch (e) {
    inserted = false;
  }
  if (inserted && inputElm.value.length !== expectedLen) {
    // firefoxでなぜかうまくいってないくせにinsertedがtrueになるので失敗を検知してfalseに…
    inserted = false;
  }

  if (!inserted) {
    try {
      document.execCommand('ms-beginUndoUnit');
    } catch (e) {}
    const value = inputElm.value;
    const before = value.slice(0, selectionStart);
    const after = value.slice(selectionEnd);
    inputElm.value = before + pre + selectedText + suf + after;
    try {
      document.execCommand('ms-endUndoUnit');
    } catch (e) {}
  }
};
