import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import styled from 'styled-components';

interface Props {
  onAddButtonClick: () => void;
}

const NotFoundTemplate = ({ onAddButtonClick }: Props) => {
  return (
    <Layout>
      <Center>
        <p>このページはまだ作成されていません。</p>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={onAddButtonClick}
        >
          新規作成
        </Button>
      </Center>
    </Layout>
  );
};

const Layout = styled.div`
  height: 100vh;
  width: 100vw;
  display: table;
`;
const Center = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  height: 100%;
`;
export default NotFoundTemplate;
