import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import HomeIcon from '@mui/icons-material/Home';
import React from 'react';
import { Frontmatter } from '../../utils/markdown';
import PrimaryFab from '../atoms/PrimaryFab';
import TitleWithButtons from '../molecules/TitleWithButtons';
import Preview from '../organisms/editor/Preview';
import Header from '../organisms/Header';
import './ress.css';
import './theme.scss';
interface Props {
  url: string;
  moveToHome: () => void;
  frontmatter: Frontmatter;
  markdown: string;
  onEditButtonClick: () => void;
}

const PageViewerTemplate: React.FC<Props> = ({
  url,
  moveToHome,
  frontmatter,
  markdown,
  onEditButtonClick,
}: Props) => {
  if (url.startsWith('/') || url.endsWith('/')) {
    throw Error('url must not start/end with slash');
  }

  const backButton = React.useMemo(() => {
    return (
      <IconButton onClick={moveToHome}>
        <HomeIcon fontSize="small" />
      </IconButton>
    );
  }, [moveToHome]);
  return (
    <>
      <Header>
        <TitleWithButtons
          title={`/${url} プレビュー`}
          titleAlign={'left'}
          backButton={backButton}
        />
      </Header>
      <Preview frontmatter={frontmatter} markdown={markdown} url={url} />
      <PrimaryFab onClick={onEditButtonClick} icon={<EditIcon />} />
    </>
  );
};

export default PageViewerTemplate;
