import React from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
  titleAlign?: 'left' | 'center';
  actions?: React.ReactNode[];
  backButton?: React.ReactNode;
}
const TitleWithButtons = ({
  title,
  titleAlign = 'center',
  actions,
  backButton,
}: Props) => {
  return (
    <Layout>
      {backButton}
      <Title align={titleAlign}>{title}</Title>
      <RightButtonArea>
        {actions?.map((elm, index) => (
          <React.Fragment key={index}>{elm}</React.Fragment>
        ))}
      </RightButtonArea>
    </Layout>
  );
};

const Layout = styled.div`
  height: 48px;
  width: 100%;
  display: flex;
`;
interface TitleProps {
  align: 'left' | 'center';
}
const Title = styled.p<TitleProps>`
  text-align: ${(props) => props.align};
  font-size: 14px;
  height: 100%;
  padding: 0;
  margin: 0;
  line-height: 48px;
  flex-grow: 1; // others are 0
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const RightButtonArea = styled.div``;

export default TitleWithButtons;
