import React from 'react';

interface Props {
  html?: React.ReactNode;
}
const GatsbyHtml: React.FC<Props> = ({ html }) => {
  /* wwwの構造と合わせる */
  return (
    <div id="___gatsby">
      <main>
        <article>
          <div>{html}</div>
        </article>
      </main>
    </div>
  );
};

export default GatsbyHtml;
