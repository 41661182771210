import React from 'react';

interface Props {
  fileType: string;
  content: string;
  alt?: string;
  imgProps?: React.ImgHTMLAttributes<HTMLImageElement>;
}
const EncodedImage = ({ fileType, content, alt, imgProps }: Props) => {
  // 一旦「拡張子＝ファイルタイプ」で決め打ち
  try {
    const dataUrl = `data:image/${fileType};base64,${content}`;
    return (
      <img {...imgProps} alt={alt} src={dataUrl} style={{ maxWidth: '100%' }} />
    );
  } catch (e) {
    console.log(e);
    return <>拡張子からファイルを判断できませんでした</>;
  }
};

export default EncodedImage;
